import { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

const initialState = {
  from_name: '',
  reply_to: '',
  message: '',
}
export const Contact = (props) => {
  const [{ from_name, reply_to, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()        
    emailjs
      .sendForm(
        'service_hd6su47', 'template_h6cp75r', e.target, 'user_nChVM6XWzfHOtU9LhTg4F'
      )
      .then(
        (response) => {
          clearState()
          toast.success("Su mensaje enviado correctamente, muchas gracias." , 
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        },
        (error) => {
          toast.error("Ocurrió un error al enviar mensaje, por favor intente comunicarse por otro medio." , 
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })          
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contáctenos</h2>
                <p>
                  Por favor complete el formulario debajo con su consulta 
                  y nos pondremos en contacto con usted lo antes posible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className="sr-only" htmlFor="from_name">Nombre</label>
                      <input
                        type='text'
                        id='from_name'
                        name='from_name'
                        className='form-control'
                        placeholder='Nombre'
                        required title='Por favor complete este campo.'
                        onChange={handleChange}
                        value={from_name}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className="sr-only" htmlFor="email">Email</label>
                      <input
                        type='email'
                        id='reply_to'
                        name='reply_to'
                        className='form-control'
                        placeholder='Email'
                        required title='Ingrese una dirección de correo válida.'
                        onChange={handleChange}
                        value={reply_to}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label className="sr-only" htmlFor="message">Mensaje</label>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensaje'
                    required title='Por favor ingrese un mensaje.'
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar Mensaje
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Información</h3>
              <p>
                <a href="https://maps.google.com/maps?z=16&q=av%2B1%2By%2Bpaseo%2B104%2C%2Bpalmeras%2Bix%2Bdpto%2B21%2C%2Bvilla%2Bgesell." 
                target="_blank" rel="noreferrer" style={{ color: '#ffffff', opacity: "0.7" }}>
                  <span>
                    <i className='fa fa-map-marker'></i> Dirección
                  </span>
                  {props.data ? props.data.address : 'loading'}
                </a>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Teléfono
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; Edificios Palmeras - Villa Gesell - Argentina
          </p>
        </div>
      </div>
    </div>
  )
}
