export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Galería</h2>
          <p>
            Disfrute de una estadía única en cualquier momento del año.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/camino-playa-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Playa a un paso</h4>
                    </div>
                    <img
                      src='img/portfolio/camino-playa-small.jpg'
                      className='img-responsive'
                      alt='Playa a un paso'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/amanecer-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Amanecer</h4>
                    </div>
                    <img
                      src='img/portfolio/amanecer-small.jpg'
                      className='img-responsive'
                      alt='Amanecer'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/invierno-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Invierno</h4>
                    </div>
                    <img
                      src='img/portfolio/invierno-small.jpg'
                      className='img-responsive'
                      alt='Invierno'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/fin-de-tarde-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Fin de Tarde</h4>
                    </div>
                    <img
                      src='img/portfolio/fin-de-tarde-small.jpg'
                      className='img-responsive'
                      alt='Fin de Tarde'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/frente-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Entrada Principal</h4>
                    </div>
                    <img
                      src='img/portfolio/frente-small.jpg'
                      className='img-responsive'
                      alt='Entrada Principal'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/luna-llena-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Luna Llena</h4>
                    </div>
                    <img
                      src='img/portfolio/luna-llena-small.jpg'
                      className='img-responsive'
                      alt='Luna Llena'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/dia-de-playa-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Día de Playa</h4>
                    </div>
                    <img
                      src='img/portfolio/dia-de-playa-small.jpg'
                      className='img-responsive'
                      alt='Día de Playa'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/mayo-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Día de Mayo</h4>
                    </div>
                    <img
                      src='img/portfolio/mayo-small.jpg'
                      className='img-responsive'
                      alt='Día de Mayo'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/verano-large.jpg'
                    title='Edificios Palmeras'
                    target="_blank" rel="noreferrer"
                  >
                    <div className='hover-text'>
                      <h4>Verano</h4>
                    </div>
                    <img
                      src='img/portfolio/verano-small.jpg'
                      className='img-responsive'
                      alt='Verano'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
